<template>
  <DialogFull
    ref="dialog"
    :data="workerDialog"
    progress
    :steps="steps"
    @onStepBack="stepBack"
    @close="onClose"
  >
    <template v-slot:dialog.body>
      <NewWorkerForm
        ref="form"
        :showValidationErrors="showValidationErrors"
        :steps="steps"
        :worker="newWorker"
        :permissions="permissions"
        :key="workerCreated"
        @onChange="handleChange"
      />
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton
        v-if="steps.current === steps.total"
        :loading="isCreating"
        @click.native="updateCreatedWorker"
      >
        Create Worker
      </PrimaryButton>
      <PrimaryButton :loading="isCreating" @click.native="nextStep" v-else>
        Continue
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import NewWorkerForm from "@/components/workers/NewWorkerForm";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  every,
  filter,
  first,
  isEmpty,
  map,
  merge,
  remove,
  size
} from "lodash";
import {
  ADDITIONAL_COMPLIANCE_CHECKS,
  COMPLIANCE_NAMES,
  CREATE_WORKER_STEPS,
  INIT_WORKER_DATA,
  VALIDATIONS
} from "@/constants/workers";
import {
  ADD_NEW_REFERENCE,
  ADD_NEW_WORKER,
  DELETE_WORKER,
  UPDATE_WORKER
} from "@/store/modules/workers/action-types";
import {
  ADD_NEW_ADDRESS,
  UPDATE_ADDRESS
} from "@/store/modules/addresses/action-types";
import {
  CREATE_ORGANISATION,
  UPDATE_ORGANISATION
} from "@/store/modules/organisations/actions";
import {
  CLEAR_CURRENT_WORKER,
  CLEAR_INTERMEDIARY_COMPANY,
  SET_WORKER
} from "@/store/modules/workers/mutation-types";
import { getComplianceChecks } from "@/utils/workers";
import { formatDate } from "@/utils/time";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { validatonScrollMixin } from "@/mixins/validation-scroll.mixin";
import { GET_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/getters";
import { FETCH_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/actions";

export default {
  name: "NewWorker",
  mixins: [validatonScrollMixin],
  components: {
    DialogFull,
    NewWorkerForm,
    PrimaryButton
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      newWorker: merge({}, INIT_WORKER_DATA),
      steps: {
        current: 1,
        total: process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP !== "true" ? 7 : 6,
        list: CREATE_WORKER_STEPS
      },
      showValidationErrors: false,
      isCreating: false,
      workerCreated: false,
      workerCompliances: [],
      workerReferences: [],
      snackbar: {
        model: false
      }
    };
  },
  async mounted() {
    if (!this.complianceCheckTypes || !this.complianceCheckTypes.length) {
      await this.fetchComplianceCheckTypes();
    }
    const additionalComplianceChecks = filter(
      ADDITIONAL_COMPLIANCE_CHECKS,
      ({ label }) => this.complianceCheckTypes.includes(label)
    );
    if (!additionalComplianceChecks || !additionalComplianceChecks.length) {
      remove(this.steps.list, step => step === "additionalCompliance");
    }
    this.steps.total = this.steps.list.length;
  },
  computed: {
    ...mapState("workers", {
      currentWorkerId: state => state.currentWorkerId
    }),
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    ...mapGetters("data", {
      complianceCheckTypes: GET_COMPLIANCE_CHECK_TYPES
    }),
    workerDialog() {
      return {
        dialog: this.isOpen,
        title: "New Worker"
      };
    },
    willCreate() {
      return this.steps.current === 1 && !this.workerCreated;
    },
    createWorkerBasicDetails() {
      const {
        firstName,
        lastName,
        email,
        phone,
        dob,
        address,
        yearsReferenced,
        status,
        incomeTypeName
      } = this.newWorker;

      return {
        firstName,
        lastName,
        email,
        phone,
        dob,
        address,
        yearsReferenced,
        status,
        incomeTypeName
      };
    }
  },
  methods: {
    ...mapActions("workers", {
      addNewWorker: ADD_NEW_WORKER,
      addNewReference: ADD_NEW_REFERENCE,
      updateWorker: UPDATE_WORKER,
      deleteWorker: DELETE_WORKER
    }),
    ...mapActions("addresses", {
      addNewAddress: ADD_NEW_ADDRESS,
      updateAddress: UPDATE_ADDRESS
    }),
    ...mapActions("organisations", {
      createOrganisation: CREATE_ORGANISATION,
      updateOrganisation: UPDATE_ORGANISATION
    }),
    ...mapMutations("workers", {
      clearIntermediaryCompany: CLEAR_INTERMEDIARY_COMPANY,
      clearCurrentWorker: CLEAR_CURRENT_WORKER,
      setWorker: SET_WORKER
    }),
    ...mapActions("data", {
      fetchComplianceCheckTypes: FETCH_COMPLIANCE_CHECK_TYPES
    }),
    stepBack() {
      this.steps.current--;
    },
    resetStep() {
      this.steps.current = 1;
    },
    onClose() {
      if (this.currentWorkerId) {
        this.deleteWorker({ id: this.currentWorkerId, hideMessage: true });
      }
      this.resetFormData();
      this.$emit("close");
    },
    resetFormData() {
      this.resetWorkerData();
      this.resetStep();
      this.$emit("close");
    },
    async createWorker() {
      this.isCreating = true;
      try {
        const worker = await this.addNewWorker(this.createWorkerBasicDetails);
        this.newWorker = merge(this.newWorker, {
          complianceChecks: getComplianceChecks(worker.complianceChecks),
          id: worker.id
        });
        this.workerCreated = true;
      } catch (err) {
        throw err;
      } finally {
        this.isCreating = false;
      }
    },
    async updateCreatedWorker() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.form.$refs.diversity.validate();
      if (isValid) {
        this.showValidationErrors = false;
        this.isCreating = true;
        this.storeWorkerCompliancesAndReferences();
        const updatedWorker = {
          ...this.newWorker,
          userComplianceChecks: this.getWorkerCompliances()
        };
        try {
          await this.addNewReferences();
          const worker = await this.updateWorker({
            updatedWorker,
            hideMessage: true
          });
          this.setWorker(worker);
          this.resetFormData();
          this.$emit("created");
        } catch (error) {
          console.error(error);
        } finally {
          this.isCreating = false;
        }
      }
    },
    resetWorkerData() {
      this.newWorker = merge({}, INIT_WORKER_DATA);
      this.workerCreated = false;
    },
    storeWorkerCompliancesAndReferences() {
      this.workerCompliances = this.newWorker.complianceChecks;
      this.workerReferences = this.newWorker.newReferences;
      delete this.newWorker.complianceChecks;
      delete this.newWorker.references;
    },
    async addNewReferences() {
      const id = this.currentWorkerId;
      const newReferences = [];
      await Promise.all(
        map(this.workerReferences, async reference => {
          delete reference.id;
          reference.user = { id };
          if (reference.periodStartDate) {
            await this.addNewReference(reference).then(data => {
              data.periodStartDate = formatDate({ date: data.periodStartDate });
              data.periodEndDate = formatDate({ date: data.periodEndDate });
              data.referenceReceivedDate = formatDate({
                date: data.referenceReceivedDate
              });
              newReferences.push(data);
            });
          }
        })
      );
      return newReferences;
    },
    getWorkerCompliances() {
      return map(this.workerCompliances, compliance => {
        const {
          id,
          complianceCheckName,
          checkVerified,
          documentTypeName,
          documentReferenceNumber,
          documentValidFromDate,
          documentValidToDate,
          registeringBody,
          checkHasUpdateService,
          files
        } = compliance;
        const attributes = {
          complianceCheckName,
          checkVerified,
          documentTypeName,
          documentReferenceNumber,
          documentValidFromDate,
          documentValidToDate,
          registeringBody,
          checkHasUpdateService,
          files
        };

        return {
          id,
          type: "compliance-checks",
          attributes
        };
      });
    },
    async nextStep() {
      this.showValidationErrors = true;
      const {
        REFERENCES,
        RTW,
        FTF,
        DBS,
        HCPC,
        QTS,
        BL,
        ST,
        HS,
        CT,
        GDPRT,
        RF,
        CONTRACT,
        SMC,
        DL,
        EDT
      } = COMPLIANCE_NAMES;
      let {
        IS_VALID,
        RIGHT_TO_WORK,
        EMPLOYMENT_HISTORY,
        DBSV,
        HCPC_CCWW_SSSC,
        QTS_NCTL_QUALIFICATION,
        FACE_TO_FACE_INTERVIEW,
        BARRED_LIST,
        SAFEGUARDING,
        HEALTHANDSAFETY,
        CYBERSECURITY,
        GDPR_TRAINING,
        REGISTRATION_FORM,
        CONTRACT_VALIDATION,
        SOCIAL_MEDIA_CHECK,
        DRIVING_LICENCE,
        FILES
      } = VALIDATIONS;
      this.$nextTick(() => {
        this.$refs.dialog.$refs.body.scrollTop = 0;
      });

      const currentStep = this.steps.list[this.steps.current - 1];
      switch (currentStep) {
        case "basic":
          IS_VALID = await this.$refs.form.$refs.basicDetailsValidation.validate();
          break;
        case "income":
          this.isCreating = true;
          IS_VALID =
            this.newWorker.incomeType !== "PAYE"
              ? (await this.handleWorkerIntermediary()) &&
                (await this.$refs.form.$refs.incomeType.validate())
              : true;
          this.isCreating = false;
          break;
        case "standardCompliance":
          await Promise.all(
            map(this.newWorker.complianceChecks, async compliance => {
              if (
                compliance.complianceCheckName === RTW &&
                compliance.checkVerified
              ) {
                RIGHT_TO_WORK = await first(
                  this.$refs.form.$refs.standardCompliance.$refs
                    .rightToWorkValidation
                ).validate();
              }

              if (
                compliance.complianceCheckName === REFERENCES &&
                compliance.checkVerified
              ) {
                EMPLOYMENT_HISTORY = await first(
                  this.$refs.form.$refs.standardCompliance.$refs
                    .employmentValidation
                ).validate();
              }

              if (
                compliance.complianceCheckName === FTF &&
                compliance.checkVerified
              ) {
                FACE_TO_FACE_INTERVIEW = await first(
                  this.$refs.form.$refs.standardCompliance.$refs
                    .faceToFaceValidation
                ).validate();
              }

              if (
                compliance.complianceCheckName === DL &&
                compliance.checkVerified
              ) {
                DRIVING_LICENCE = await first(
                  this.$refs.form.$refs.standardCompliance.$refs.DLExpiryDate
                ).$refs.validation.validate();
              }

              FILES = every(
                this.$refs.form.$refs.standardCompliance.$refs.complianceFiles,
                complianceFile => {
                  return (
                    !complianceFile.required || !!complianceFile.files.length
                  );
                }
              );
              return;
            })
          );
          IS_VALID =
            RIGHT_TO_WORK &&
            EMPLOYMENT_HISTORY &&
            FACE_TO_FACE_INTERVIEW &&
            DRIVING_LICENCE &&
            FILES;
          break;
        case "additionalCompliance":
          await Promise.all(
            map(this.newWorker.complianceChecks, async compliance => {
              if (
                compliance.complianceCheckName === DBS &&
                compliance.checkVerified
              ) {
                DBSV = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs.dbs
                ).$refs.validation.validate();
              }

              if (
                compliance.complianceCheckName === HCPC &&
                compliance.checkVerified
              ) {
                HCPC_CCWW_SSSC = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs.HCPCNumber
                ).$refs.validation.validate();
                HCPC_CCWW_SSSC = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .HCPCExpiryDate
                ).$refs.validation.validate();
              }
              if (
                compliance.complianceCheckName === ST &&
                compliance.checkVerified
              ) {
                SAFEGUARDING = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs.STExpiryDate
                ).$refs.validation.validate();
              }

              if (
                compliance.complianceCheckName === QTS &&
                compliance.checkVerified
              ) {
                QTS_NCTL_QUALIFICATION = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs.QTSNumber
                ).$refs.validation.validate();
              }

              if (
                compliance.complianceCheckName === BL &&
                compliance.checkVerified
              ) {
                BARRED_LIST = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .barredListDate
                ).$refs.validation.validate();
              }
              if (
                compliance.complianceCheckName === HS &&
                compliance.checkVerified
              ) {
                HEALTHANDSAFETY = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .hsCompletionDate
                ).$refs.validation.validate();
                console.log(HEALTHANDSAFETY);
              }
              if (
                compliance.complianceCheckName === CT &&
                compliance.checkVerified
              ) {
                CYBERSECURITY = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .ctCompletionDate
                ).$refs.validation.validate();
                console.log(CYBERSECURITY);
              }
              if (
                compliance.complianceCheckName === GDPRT &&
                compliance.checkVerified
              ) {
                GDPR_TRAINING = await first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .gdprtCompletionDate
                ).$refs.validation.validate();
              }
              if (
                compliance.complianceCheckName === RF &&
                compliance.checkVerified
              ) {
                REGISTRATION_FORM =
                  (await first(
                    this.$refs.form.$refs.additionalCompliance.$refs
                      .RFExpiryDate
                  ).$refs.validation.validate()) &&
                  size(
                    first(
                      this.$refs.form.$refs.additionalCompliance.$refs
                        .complianceFiles
                    ).files
                  );
              }
              if (
                compliance.complianceCheckName === EDT &&
                compliance.checkVerified
              ) {
                REGISTRATION_FORM =
                  (await first(
                    this.$refs.form.$refs.additionalCompliance.$refs
                      .EDTCompletionDate
                  ).$refs.validation.validate()) &&
                  size(
                    first(
                      this.$refs.form.$refs.additionalCompliance.$refs
                        .complianceFiles
                    ).files
                  );
              }

              if (
                compliance.complianceCheckName === CONTRACT &&
                compliance.checkVerified
              ) {
                CONTRACT_VALIDATION = size(
                  first(
                    this.$refs.form.$refs.additionalCompliance.$refs
                      .complianceFiles
                  ).files
                );
              }

              if (
                compliance.complianceCheckName === SMC &&
                compliance.checkVerified
              ) {
                SOCIAL_MEDIA_CHECK = size(
                  first(
                    this.$refs.form.$refs.additionalCompliance.$refs
                      .complianceFiles
                  ).files
                );
              }
              return;
            })
          );
          IS_VALID =
            DBSV &&
            HCPC_CCWW_SSSC &&
            QTS_NCTL_QUALIFICATION &&
            BARRED_LIST &&
            SAFEGUARDING &&
            HEALTHANDSAFETY &&
            CYBERSECURITY &&
            GDPR_TRAINING &&
            REGISTRATION_FORM &&
            CONTRACT_VALIDATION &&
            SOCIAL_MEDIA_CHECK;
          break;
        default:
          IS_VALID = true;
      }
      if (IS_VALID) {
        if (this.willCreate) {
          await this.createWorker();
          this.showValidationErrors = false;
          this.steps.current++;
        } else {
          this.showValidationErrors = false;
          this.steps.current++;
        }
      } else {
        this.scrollToErrorMessage();
      }
    },
    async handleWorkerIntermediary() {
      let isSuccess = true;
      if (isEmpty(this.newWorker.company)) {
        return isSuccess;
      }

      if (isEmpty(this.newWorker.intermediaryOrganisation)) {
        this.newWorker.company.organisationType = "intermediary";
        this.newWorker.intermediaryOrganisation = this.newWorker.company;
        isSuccess = await this.createOrganisation(
          this.newWorker.intermediaryOrganisation
        )
          .then(id => {
            this.newWorker.company.id = id;
            this.newWorker.intermediaryOrganisation.id = id;
            return true;
          })
          .catch(() => {
            return false;
          });
      } else {
        isSuccess = await this.updateOrganisation(this.newWorker.company)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      if (!isEmpty(this.newWorker.companyAddress)) {
        if (!this.newWorker.companyAddress.id) {
          isSuccess = await this.addNewAddress(this.newWorker.companyAddress)
            .then(id => {
              this.newWorker.intermediaryOrganisation.addresses = {
                data: { type: "addresses", id }
              };
              this.updateOrganisation(this.newWorker.intermediaryOrganisation);
              this.newWorker.companyAddress.id = id;
              return true;
            })
            .catch(() => {
              return false;
            });
        } else {
          isSuccess = await this.updateAddress(this.newWorker.companyAddress)
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }
      }

      return isSuccess;
    },
    handleChange(updatedWorker) {
      this.newWorker = { ...this.newWorker, ...updatedWorker };
    }
  }
};
</script>
